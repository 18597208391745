<svg
    class="h-12 w-auto fill-current text-black dark:text-white"
    width="45"
    height="16"
    viewBox="0 0 45 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clip-path="url(#clip0_207_2)">
        <mask
            id="mask0_207_2"
            style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="0"
            y="-2"
            width="45"
            height="18"
        >
            <path d="M45 -2H0V16H45V-2Z" fill="white"></path>
        </mask>
        <g mask="url(#mask0_207_2)">
            <path
                d="M41.0419 8.63326C40.9863 8.63326 40.932 8.61642 40.8863 8.58496C40.8404 8.5535 40.8052 8.50889 40.7852 8.45702L40.4691 7.63501C40.4623 7.61704 40.4517 7.60071 40.438 7.58711C40.4245 7.57351 40.4082 7.56293 40.3902 7.55608L39.5682 7.23995C39.5163 7.21998 39.4718 7.18476 39.4403 7.13894C39.4088 7.09313 39.392 7.03884 39.392 6.98327C39.392 6.92769 39.4088 6.87341 39.4403 6.82759C39.4718 6.78177 39.5163 6.74656 39.5682 6.72658L40.3902 6.41045C40.4082 6.40357 40.4245 6.39299 40.438 6.37939C40.4517 6.36579 40.4623 6.34948 40.4691 6.33152L40.7825 5.51683C40.8001 5.46887 40.8305 5.42663 40.8704 5.39465C40.9103 5.36267 40.958 5.34216 41.0087 5.33532C41.0695 5.32793 41.1311 5.34105 41.1836 5.37261C41.2361 5.40417 41.2766 5.45235 41.2986 5.50952L41.6148 6.33152C41.6216 6.34948 41.6322 6.36579 41.6458 6.37939C41.6594 6.39299 41.6757 6.40357 41.6937 6.41045L42.5157 6.72658C42.5675 6.74656 42.6121 6.78177 42.6436 6.82759C42.6751 6.87341 42.6919 6.92769 42.6919 6.98327C42.6919 7.03884 42.6751 7.09313 42.6436 7.13894C42.6121 7.18476 42.5675 7.21998 42.5157 7.23995L41.6937 7.55608C41.6757 7.56293 41.6594 7.57351 41.6457 7.58711C41.6322 7.60071 41.6216 7.61704 41.6148 7.63501L41.2986 8.45702C41.2787 8.50889 41.2435 8.5535 41.1976 8.58496C41.1518 8.61642 41.0976 8.63326 41.0419 8.63326Z"
                fill="currentColor"
            ></path>
            <path
                d="M9.88587 8.20479C9.77632 8.82203 9.51755 9.33972 9.10933 9.75785C8.71115 10.1661 8.23325 10.4448 7.67573 10.5941C8.18347 10.7136 8.57675 10.9575 8.85547 11.3259C9.14421 11.6942 9.28853 12.1323 9.28853 12.64C9.28853 12.7992 9.2736 12.9635 9.24373 13.1328C9.0944 14.0288 8.66635 14.7307 7.95947 15.2384C7.25262 15.7461 6.33671 16 5.21173 16H0L1.86667 5.47198H6.91413C7.86987 5.47198 8.61152 5.66612 9.1392 6.05438C9.66688 6.4327 9.93067 6.97527 9.93067 7.68212C9.93067 7.85137 9.91573 8.02559 9.88587 8.20479ZM6.79467 8.74238C6.80462 8.6926 6.8096 8.61794 6.8096 8.51838C6.8096 8.04052 6.496 7.80158 5.8688 7.80158H4.3904L4.06187 9.66825H5.54027C6.26702 9.66825 6.68515 9.35963 6.79467 8.74238ZM6.33173 12.7147C6.34169 12.6648 6.34667 12.5952 6.34667 12.5056C6.34667 12.2567 6.26205 12.0675 6.0928 11.9381C5.93352 11.7987 5.69458 11.7291 5.376 11.7291H3.68853L3.36 13.6555H5.0624C5.78915 13.6555 6.21227 13.3419 6.33173 12.7147ZM24.1756 5.47198L22.3089 16H19.3969L20.4273 10.176L17.3958 16H14.9468L13.991 10.1461L12.9606 16H10.0188L11.8854 5.47198H15.4246L16.8732 12.2965L20.6513 5.47198H24.1756ZM33.7414 16H30.7996L27.9622 10.1163L26.9169 16H23.975L25.8417 5.47198H28.7686L31.6209 11.4304L32.6662 5.47198H35.5932L33.7414 16Z"
                fill="currentColor"
            ></path>
            <path
                d="M42.5584 4.20012C42.4978 4.2001 42.4386 4.1817 42.3886 4.14736C42.3387 4.11301 42.3003 4.06434 42.2785 4.00773L41.8503 2.89455C41.8427 2.87493 41.8312 2.85712 41.8163 2.84226C41.8014 2.8274 41.7836 2.81583 41.764 2.8083L40.6508 2.38005C40.5943 2.35826 40.5456 2.31985 40.5114 2.26988C40.477 2.2199 40.4587 2.16072 40.4587 2.10012C40.4587 2.03951 40.477 1.98032 40.5114 1.93035C40.5456 1.88037 40.5943 1.84196 40.6508 1.82017L41.764 1.39192C41.7836 1.38439 41.8014 1.37282 41.8163 1.35796C41.8312 1.3431 41.8427 1.32529 41.8503 1.30567L42.2753 0.200362C42.2947 0.148095 42.3279 0.102058 42.3714 0.0671675C42.4148 0.0322661 42.467 0.00980213 42.5223 0.00217546C42.5886 -0.00585974 42.6558 0.00850078 42.7131 0.0429861C42.7703 0.0774608 42.8144 0.130079 42.8384 0.19249L43.2666 1.30567C43.2741 1.32529 43.2858 1.3431 43.3006 1.35796C43.3154 1.37282 43.3332 1.38439 43.3529 1.39192L44.466 1.82017C44.5226 1.84196 44.5712 1.88037 44.6056 1.93035C44.6398 1.98032 44.6581 2.03951 44.6581 2.10012C44.6581 2.16072 44.6398 2.2199 44.6056 2.26988C44.5712 2.31985 44.5226 2.35826 44.466 2.38005L43.3529 2.8083C43.3332 2.81583 43.3154 2.8274 43.3006 2.84226C43.2858 2.85712 43.2741 2.87493 43.2666 2.89455L42.8384 4.00773C42.8167 4.06434 42.7783 4.11301 42.7282 4.14736C42.6783 4.1817 42.6191 4.2001 42.5584 4.20012Z"
                fill="currentColor"
            ></path>
            <path
                d="M37.7923 6.39998C37.722 6.40034 37.6534 6.37926 37.5955 6.33955C37.5376 6.29984 37.4931 6.24341 37.4681 6.17779L36.9149 4.73906C36.9037 4.71006 36.8865 4.68373 36.8645 4.66175C36.8425 4.63977 36.8162 4.62263 36.7873 4.61143L35.3481 4.05773C35.2825 4.03258 35.2261 3.98813 35.1863 3.93027C35.1466 3.87239 35.1253 3.80382 35.1253 3.7336C35.1253 3.66339 35.1466 3.59482 35.1863 3.53695C35.2261 3.47909 35.2825 3.43464 35.3481 3.40948L36.7869 2.85621C36.8158 2.845 36.8421 2.82786 36.8641 2.80588C36.8861 2.7839 36.9032 2.75757 36.9144 2.72858L37.4681 1.28943C37.4933 1.22388 37.5378 1.16749 37.5956 1.12772C37.6535 1.08794 37.722 1.06665 37.7923 1.06665C37.8625 1.06665 37.9311 1.08794 37.9889 1.12772C38.0468 1.16749 38.0913 1.22388 38.1163 1.28943L38.6696 2.72817C38.6808 2.75716 38.698 2.78348 38.72 2.80547C38.7419 2.82744 38.7683 2.84458 38.7973 2.85579L40.2277 3.40614C40.2959 3.43143 40.3546 3.47712 40.396 3.53702C40.4373 3.5969 40.4592 3.66807 40.4586 3.74083C40.4576 3.80983 40.4358 3.87691 40.3962 3.93341C40.3566 3.98991 40.301 4.03322 40.2365 4.05773L38.7977 4.61101C38.7687 4.62221 38.7424 4.63935 38.7204 4.66134C38.6984 4.68331 38.6813 4.70965 38.6701 4.73864L38.1163 6.17779C38.0914 6.24341 38.047 6.29984 37.9891 6.33955C37.9312 6.37926 37.8625 6.40034 37.7923 6.39998Z"
                fill="currentColor"
            ></path>
        </g>
    </g>
    <defs>
        <clipPath id="clip0_207_2">
            <rect width="45" height="16" fill="white"></rect>
        </clipPath>
    </defs>
</svg>
